
import { mapGetters } from 'vuex';

import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';

export default {
  name: 'HeroSection',

  computed: {
    ...mapGetters({
      promoDetails: 'getPromoDetails',
      getVariantHeroSectionBannerImage: 'getVariantHeroSectionBannerImage'
    }),
    buttonStyle() {
      return this.getVariantHeroSectionBannerImage === 'heroSectionBannerImage'
        ? {
            'margin-bottom': '16px'
          }
        : {};
    },
    marginBottomStyle() {
      return this.getVariantHeroSectionBannerImage === 'heroSectionBannerImage'
        ? {
            'margin-bottom': '48px'
          }
        : {};
    }
  },

  methods: {
    goToHire() {
      Gtm.sendCustomEvent(
        'click',
        `cabem-no-bolso:botao:contratar-agora`,
        'lp-saude'
      );
      const url = navigateToCheckout({ params: this.$route.query });
      this.$router.push(url);
    },

    goToPlanSection() {
      Gtm.sendCustomEvent(
        'click',
        `cabem-no-bolso:botao:conhecer-planos`,
        'lp-saude'
      );

      this.$scrollTo('.section-plans__card-group');
    }
  }
};
